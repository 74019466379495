import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { AuthService } from './auth.service';
import { AuthGuard } from './auth.guard';
import { LoginGuard } from './login.guard';

const routes: Routes = [
	{
		path: '',
		component: LoginComponent,
		canActivate: [LoginGuard]
	},
	{
		path: 'home',
		component: HomeComponent,
		canActivate: [AuthGuard]
	},
    {
        path: '**',
        component: HomeComponent,
        canActivate: [AuthGuard]
    },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  declarations: []
})
export class AppRoutingModule { }

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../environments/environment';

interface myUrlResponse {
  status: string
}
declare var VEDEM_API_URL;
interface myFileResponse {
  status: string
  message: string
  data: any
}

@Injectable({
  providedIn: 'root'
})
export class HomeService {
  apiUrl = VEDEM_API_URL;
  getFiles(token){
  	let tokenV = token
  	const httpOption = {
		headers: new HttpHeaders({
    		'X-USER-TOKEN': tokenV,
  		})
	};
  	return this.http.get<myFileResponse>(VEDEM_API_URL+'/tasks', httpOption)
  }
  
  sendUrlDetails(url, token){
  	let tokenValue = token
  	const httpOptions = {
		headers: new HttpHeaders({
    		'X-USER-TOKEN': tokenValue,
  		})
	};
  	return this.http.post<myUrlResponse>(VEDEM_API_URL+'/tasks', { url }, httpOptions)
  }

  constructor( private http: HttpClient ) { }
}

<div class="container">
  <div class="h-100 row align-items-center">
    <form class="mg-form" (submit)="loginUser($event)">       
      <h2 i18n="VEDEM Collector">VEDEM Collector </h2>
      <hr>
      <div class="form-group">
        <input type="email" id="email" class="form-control" name="username" value="" placeholder="Enter Your Email" required="" i18n-placeholder="Enter Your Email"/>
      </div>
      <div class="form-group">
        <input type="password" id="password" class="form-control" name="password" value="" placeholder="Enter Your Password" required="" i18n-placeholder="Enter Your Password"/>      
      </div>
      <div class="form-group">
        <label class="remember-me-checkbox form-inline">
          <input type="checkbox" value="remember-me" id="rememberMe" name="rememberMe"><p class="checkbox-text" i18n="Remember me for two weeks"> Remember me for two weeks</p>
        </label>
      </div>
      <div class="d-flex justify-content-center">
        <button class="btn btn-default" type="submit" i18n="Sign In">
          Sign In
        </button>
      </div>
    </form>
  </div>
  <div class="alert alert-warning"></div>
</div>
import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth.service';
import { HomeService } from '../home.service';
import { Router } from '@angular/router';
import { Observable, interval } from 'rxjs';
import * as $ from 'jquery';
import { map } from 'rxjs/operators'
import { environment } from '../../environments/environment';

declare var VEDEM_API_URL;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],

})
export class HomeComponent implements OnInit {
  
  files$: Object = [];
  urlDown: String;
  constructor( private Auth: AuthService, private router: Router, private Home: HomeService ) { }

  ngOnInit() {
    const id:string = ''
    const token = this.Auth.isToken
    var fileColl  = new Array()
    this.urlDown = VEDEM_API_URL
    this.Home.getFiles(token).subscribe(data => {
      this.files$ = data.data.reverse()
    },
      error => {
        if(error.status == 401){
          this.signOut()
        }
      }
    )
    var sessionId = window.setInterval(()=>{ this.Home.getFiles(token).subscribe(data => {
      this.files$ = data.data.reverse()
    },error => {
        if(error.status == 401){
          clearInterval(sessionId)
          this.signOut()
        }
      }
      )}, 3000)
  }

  signOut(){      
  	this.Auth.clearUserInfo()
    this.router.navigate([''])
  }

  reloadPage() {
    this.router.navigate([ 'home' ]);
  }

  sendUrl(event){
    event.preventDefault();
    const target = event.target
    const url = target.querySelector('#url').value
    const token = this.Auth.isToken
    this.Home.sendUrlDetails(url, token).subscribe(data => {
        if(data.status == "SUCCESS") {
          target.querySelector('#url').value = null
          this.ngOnInit()
          this.reloadPage()
          $( ".alert.alert-success" ).empty()
          $( ".alert.alert-success" ).append( "<strong>Successful!</strong> URL successfully added." )
          $( ".alert.alert-success" ).fadeIn(2000)
          $( ".alert.alert-success" ).fadeOut(2000)
        } else {
          $( ".alert.alert-warning" ).empty()
          $( ".alert.alert-warning" ).append( "<strong>Unsuccessful!</strong> URL could not be added." )
          $( ".alert.alert-warning" ).fadeIn(2000)
          $( ".alert.alert-warning" ).fadeOut(2000)
        }
    },
      error => {
        if(error.status == 401){
          this.signOut()
        }
      })
  }
}

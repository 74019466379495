import { Injectable, Inject } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth.service';
import { Router } from '@angular/router';
import { PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  constructor(private Auth: AuthService, private router: Router, 
    @Inject(PLATFORM_ID) private platformId: any,
    @Inject('LOCALSTORAGE') private localStorage: any ) { }

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
            // localStorage will be available: we can use it.
        }
        if (isPlatformServer(this.platformId)) {
            // localStorage will be null.
    }
    if(this.Auth.isRememberMe.status){
      this.Auth.checkTimeStamp();
      console.log(document.querySelector("#email")['value'])
      document.querySelector('#email')['value'] = this.Auth.isRememberMe.email;
      document.querySelector('#password')['value'] = this.Auth.isRememberMe.password;
    }
  }

  loginUser(event){
    event.preventDefault();
    const target = event.target
    const email = target.querySelector('#email').value
    const password = target.querySelector('#password').value
    const rememberMe = target.querySelector('#rememberMe').checked
    this.Auth.getUserDetails(email, password).subscribe(data => {
      if(data.status == "SUCCESS") {
        this.Auth.setLoggedIn(true)
        this.Auth.setToken(data.token)      
        if(rememberMe){
          this.Auth.setRememberMe(rememberMe, email, password)
        }else{
          localStorage.removeItem('rememberMe')
          localStorage.removeItem('email')
          localStorage.removeItem('password')
        }
        this.router.navigate(['home'])
      }
    },
      error => {
        target.querySelector('#email').value = null
        target.querySelector('#password').value = null
        $( ".alert.alert-warning" ).empty()
        $( ".alert.alert-warning" ).append( "<strong>Login Failed!</strong> Invalid Username or Password!" )
        $( ".alert.alert-warning" ).fadeIn(2000)
        $( ".alert.alert-warning" ).fadeOut(2000)
      }
    )
  }

}

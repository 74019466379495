import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { environment } from '../environments/environment';

interface myData {
  status: string,
  token: string
}
declare var VEDEM_API_URL;
@Injectable({
  providedIn: 'root'
})


export class AuthService {
  private loggedInStatus:boolean = false
  private rememberMeStatus:boolean = false
  private token:string = "default"
  private email:string = "email"
  private password:string = "password"
  private timestamp:number = 120960000

  setLoggedIn(value: boolean){
  	if(window.localStorage){
  		localStorage.setItem(JSON.stringify(this.loggedInStatus), JSON.stringify(value))
 	  } 	
  }

  setRememberMe(value: boolean, email: string, password: string){
    if(window.localStorage){
      localStorage.setItem('rememberMe', JSON.stringify(value))
      localStorage.setItem('email', email)
      localStorage.setItem('password', password)
      let timestamp1 = Number(new Date()) + 120960000;
      localStorage.setItem('timestamp', JSON.stringify(timestamp1))
    }
  }

  setToken(value: string){
  	if(window.localStorage){
  		localStorage.setItem(this.token, value)
  	}
  }

  checkTimeStamp(){
    const ts = Number(localStorage.getItem('timestamp'))
    if(ts <= Number(Date.now())){
      localStorage.removeItem('timestamp');
      localStorage.removeItem('email');
      localStorage.removeItem('password');
      localStorage.removeItem('rememberMe');
    }
  }

  get isLoggedIn(){

  	if(window.localStorage){
  		let value = localStorage.getItem(JSON.stringify(this.loggedInStatus))
  		if(value == "true"){
        return true
      }
      else{
        return false
      }
  	}
  }

  get isRememberMe(){
    if(window.localStorage){
      let value = localStorage.getItem('rememberMe')
      let email = localStorage.getItem('email')
      let password = localStorage.getItem('password')
      if(value == "true"){
        return {status: true, email: email, password: password}
      }
      else{
        return {status: false}
      }
    }
  }

  get isToken(){
  	if(window.localStorage){
  		let tokenValue = localStorage.getItem(this.token)
  		return tokenValue
  	}
  }

  getUserDetails(email, password){
  	return this.http.post<myData>(VEDEM_API_URL+'/login',{ email, password })
  }

  clearUserInfo(){
    localStorage.removeItem(JSON.stringify(this.loggedInStatus))
    localStorage.removeItem(JSON.stringify(this.token))
  }

  constructor(private http: HttpClient, @Inject(PLATFORM_ID) private platformId: any,
        @Inject('LOCALSTORAGE') private localStorage: any) { }

  NgOnInit() {
        if (isPlatformBrowser(this.platformId)) {
            // localStorage will be available: we can use it.
        }
        if (isPlatformServer(this.platformId)) {
            // localStorage will be null.
        }
    }
}

<nav class="navbar navbar-inverse">
  <div class="container-fluid">
    <div class="navbar-header">
      <a class="navbar-brand" href="#" i18n="vedem-title">VEDEM Collector</a>
    </div>
    <ul class="nav navbar-nav navbar-right">
      <li><button (click)="signOut()" class="btn navbar-btn" i18n="Sign Out">Sign Out</button></li>
    </ul>
  </div>
</nav>
<div class="container main">
<div class="row">
    <div class="col-lg-12 mg-container">
      <form class="form-inline justify-content-lg-center" (submit)="sendUrl($event)"> 
        <input type="url" id="url" class="form-control" name="url" placeholder="Enter the url address of the document to be collected" required=""
        i18n-placeholder="Enter the url address of the document to be collected" />   
        <button class="btn btn-default mg-collect-btn" type="submit" i18n="Collect">Collect</button>   
      </form>
    </div>

    <div class="col-sm-12 mg-file-collection">
      <h3 class="h3" *ngIf=" (files$ | json) != '[]'" i18n="Collection in progress"> Collection in progress: </h3>
        <div class = container>
            <table class="table">
                <tbody>
                    <tr *ngFor="let file of files$">
                        <td class="mg-file-list-item mg-file-list-item{{file.status}}">
                            <li class="mg-status-1">
                                <div class="mg-circle-1">
                                    <div class="mg-center">
                                        1
                                    </div>
                                </div>
                            </li>
                            <li class="mg-status-2">
                                <div class="mg-circle-2">
                                    <div class="mg-center">
                                        2
                                    </div>
                                </div>
                            </li>
                            <li class="mg-status-3">
                                <div class="mg-circle-3">
                                    <div class="mg-center">
                                        3
                                    </div>
                                </div>
                            </li>
                        </td>
                        <td class="mg-filename">
                            <div *ngIf="file.name">
                                {{ file.name }}
                            </div>
                            <div *ngIf="!file.name">
                                {{ file.url }}
                            </div>
                        </td>
                        <td class="mg-download-btn">
                            <div *ngIf="file.status==10">
                                <form action = "{{ urlDown }}/download/{{ file.id }}">
                                    <input type="submit" class="btn btn-default" value="Download" i18n-value="Download" />
                                </form>
                            </div>
                            <div *ngIf="file.status!=10">
                                <form action = "{{ urlDown }}/download/{{ file.id }}">
                                    <input type="submit" class="btn btn-default" value="Download" disabled="disabled" i18n-value="Download"/>
                                </form>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
<div class="alert alert-warning"></div>
<div class="alert alert-success"></div>
</div>